<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- ZAKOMENTOWANE -->
            <!-- :class="`${isStatusClosed(item) ? 'disabled' : ''}`" -->
            <!-- === === ORDER DATE === === -->
            <td>
                <div class="orderDate service-table">
                    <!-- TODO DATE FORMATER -->
                    <DoubleLine
                        :firtst-line="
                            item && item.createdAt
                                ? $moment(item.createdAt).format('DD/MM/YYYY')
                                : ''
                        "
                        :second-line="
                            item && item.createdAt
                                ? $moment(item.createdAt).format('hh:mm')
                                : ''
                        "
                    />
                </div>
            </td>

            <!-- === === PERSON NAME AND LASTNAME === === -->
            <td class="truncate">
                <div class="personNameAndLastName">
                    <div>
                        {{ $get(item, 'name', '') }}
                        {{ $get(item, 'lastName', '') }}
                    </div>
                </div>
            </td>
            <!-- === === PERSON CONTACT === === -->
            <td class="truncate">
                <div class="personContact">
                    <div>
                        <DoubleLine
                            :firtst-line="$get(item, 'login', '')"
                            :second-line="$get(item, 'phoneNumber', '')"
                        />
                    </div>
                </div>
            </td>
            <!-- === === TYPE OF USER === === -->
            <td class="truncate">
                <div class="personContact">
                    <div>
                        <span v-if="item.isAdmin">{{
                            $t('global:admin')
                        }}</span>
                        <span v-if="!item.isAdmin">{{
                            $t('global:user')
                        }}</span>
                    </div>
                </div>
            </td>
            <!-- === === STATUS === === -->
            <td class="truncate">
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                >
                    {{ $get(item, 'status.name', $t('users:empty')) }}
                </v-chip>
            </td>
            <!-- === === ARROW === === -->
            <td class="truncate" style="text-align: end">
                <v-btn small router :to="`/adminSetup/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    computed: {
        ...mapGetters(['getProfileDetails']),
    },

    methods: {
        ...mapActions(['deleteCar']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteCar({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
    },
}
</script>
<style lang="scss" scoped>
.communication {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}
.orderDate {
    display: flex;
    justify-content: flex-start;
    word-spacing: 9999px;
}
.orderingParty {
    display: flex;
    flex-direction: column;

    & span:nth-child(2) {
        color: grey !important;
    }
    & span:nth-child(3) {
        color: grey !important;
    }
}
.deviceAndSn {
    display: flex;
    flex-direction: column;

    & .name {
        color: rgb(33, 99, 158);
        font-weight: bold;
    }
    & .se {
        color: grey;
    }
}
.interventionDate {
    display: flex;
    flex-direction: column;
}
.technician {
    display: flex;
    flex-direction: column;
}

.secondary-color {
    color: #a8a8a8;
}
</style>
