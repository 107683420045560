var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"orderDate service-table"},[_c('DoubleLine',{attrs:{"firtst-line":item && item.createdAt
                            ? _vm.$moment(item.createdAt).format('DD/MM/YYYY')
                            : '',"second-line":item && item.createdAt
                            ? _vm.$moment(item.createdAt).format('hh:mm')
                            : ''}})],1)]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"personNameAndLastName"},[_c('div',[_vm._v(" "+_vm._s(_vm.$get(item, 'name', ''))+" "+_vm._s(_vm.$get(item, 'lastName', ''))+" ")])])]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"personContact"},[_c('div',[_c('DoubleLine',{attrs:{"firtst-line":_vm.$get(item, 'login', ''),"second-line":_vm.$get(item, 'phoneNumber', '')}})],1)])]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"personContact"},[_c('div',[(item.isAdmin)?_c('span',[_vm._v(_vm._s(_vm.$t('global:admin')))]):_vm._e(),(!item.isAdmin)?_c('span',[_vm._v(_vm._s(_vm.$t('global:user')))]):_vm._e()])])]),_c('td',{staticClass:"truncate"},[_c('v-chip',{style:(("color: " + (_vm.$get(
                    item,
                    'status.color.font',
                    '#FFFFFF'
                )))),attrs:{"color":_vm.$get(item, 'status.color.background', '#555555'),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$get(item, 'status.name', _vm.$t('users:empty')))+" ")])],1),_c('td',{staticClass:"truncate",staticStyle:{"text-align":"end"}},[_c('v-btn',{attrs:{"small":"","router":"","to":("/adminSetup/" + (item._id)),"icon":""}},[_c('v-icon',{attrs:{"color":"secondary","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }