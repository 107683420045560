<template>
    <v-container id="InnerTableContainer" class="pt-10">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="$t('NotifyAdminSetup:admin-setup')"
                    avatar-data="getordersRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :items="getAdminSetupTable.items"
                    :headers="headers"
                    :length="getAdminSetupTable.pages"
                    :page="getAdminSetupTable.page"
                    :loading="getAdminSetupTable.loading"
                    :default-fields="getAdminSetupSearchFields.searchFields"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                >
                    <Columns slot="columns" :items="getAdminSetupTable.items" />
                </Table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Columns from '../../../components/Registries/AdminSetup/Table/Columns.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'AdminSetup',
    components: {
        Columns,
    },

    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/service/:id') {
            store.commit('clearAdminSetupTable')
            store.dispatch('fetchAdminSetupTable').then(() => next())
        }
        next()
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('NotifyAdminSetup:createdAt'),
                    value: 'createdAt',
                    width: '1%',
                },
                {
                    text: `${this.$t(
                        'NotifyAdminSetup:personName'
                    )} i ${this.$t('NotifyAdminSetup:personLastName')}`,
                    value: 'name join lastName',
                    width: '2%',
                    showSearch: true,
                    sortable: true,
                },

                {
                    text: this.$t('NotifyAdminSetup:personContact'),
                    value: 'login',
                    width: '3%',
                    showSearch: true,
                },
                {
                    text: this.$t('NotifyAdminSetup:typeOfUser'),
                    value: 'typeOfUser',
                    width: '1%',
                    showSearch: true,
                },
                {
                    text: this.$t('NotifyAdminSetup:status'),
                    value: 'status',
                    width: '1%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: `1%`,
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getAdminSetupTable',
            'getAdminSetupSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchAdminSetupTable']),
        ...mapMutations([
            'setAdminSetupTable',
            'clearAdminSetupTable',
            'setAdminSetupSearch',
        ]),
        setSearch(search) {
            this.setAdminSetupTable({ search, page: 1 })
            this.fetchAdminSetupTable()
        },
        setPage(page) {
            this.setAdminSetupTable({ page })
            this.fetchAdminSetupTable()
        },
        setSort(sort) {
            this.setAdminSetupTable({ sort })
            this.fetchAdminSetupTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearAdminSetupTable()
            this.fetchAdminSetupTable()
        },
        setSearchFields(searchFields) {
        
            this.setAdminSetupSearch(searchFields)
        },
    },
}
</script>

<style></style>
